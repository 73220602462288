import { css } from '@emotion/react';
import * as React from 'react';
import IconType from '~/neo-ui/packages/icon/IconType.gen';
import Color, { colorToCode } from '~/neo-ui/packages/color/Color.gen';
import { Styleable } from '~/neo-ui/model/capacity';
import useScalePadOrigin from '~/scalepad/packages/scalepad-product-script/packages/injection/hooks/useScalePadOrigin';

export type VendorIcon =
  | 'Addigy'
  | 'Atera'
  | 'Auvik'
  | 'BackupRadar'
  | 'Barracuda'
  | 'Bluetrait'
  | 'BreachSecureNow'
  | 'CiscoSmartNet'
  | 'CommitCRM'
  | 'ConnectWiseAutomate'
  | 'ConnectWiseAutomateRest'
  | 'ConnectWiseManage'
  | 'ConnectWiseRMM'
  | 'Continuum'
  | 'Crewhu'
  | 'DattoBCDR'
  | 'DattoPSA'
  | 'DattoRMM'
  | 'Freshservice'
  | 'HaloITSM'
  | 'HaloPSA'
  | 'Hudu'
  | 'ITGlue'
  | 'KaseyaBms'
  | 'KaseyaVsa'
  | 'KaseyaVSAx'
  | 'LogMeInCentral'
  | 'ManageEngine'
  | 'Meraki'
  | 'MicrosoftEndpointManagerSingleTenant'
  | 'MicrosoftEndpointManagerMultiTenant'
  | 'MicrosoftEndpointManagerMultiTenantOAuth'
  | 'Naverisk'
  | 'NCentral'
  | 'NinjaRmm'
  | 'Panorama9'
  | 'Pulseway'
  | 'PulsewayPSA'
  | 'RepairShopr'
  | 'RgSystem'
  | 'ServiceNow'
  | 'Simplesat'
  | 'SmileBack'
  | 'SolarWindsRMM'
  | 'SonicWall'
  | 'SyncroMsp'
  | 'SuperOps'
  | 'Vorex'
  | 'WarrantyMaster'
  | 'FortiGateCloud'
  | 'UbiquitiCloudPortal';

export type IconProps = {
  sizePx?: number;
  color?: Color | 'no-color';
  icon: IconType;
  onClick?: () => void;
  preventOnClickPropagation?: boolean;
} & Styleable;

const Icon: React.FunctionComponent<IconProps> = ({
  className,
  sizePx = 16,
  color = 'dark-900',
  icon,
  onClick,
  preventOnClickPropagation = false,
}) => {
  // This is needed to enable this component for use in cross-product contexts (e.g., app switcher)
  const { forceAbsolutePath } = useScalePadOrigin();

  return (
    <div
      className={className}
      css={css`
        //noinspection CssUnknownTarget
        background-image: url('${forceAbsolutePath(
          `/neo-icons/${icon}.svg?version=1${color === 'no-color' ? '' : `&color=${encodeURIComponent(colorToCode(color))}`}`,
        )}');
        background-repeat: no-repeat;
        background-size: contain;

        display: inline-block;
        min-width: ${sizePx}px;
        width: ${sizePx}px;
        height: ${sizePx}px;
      `}
      onClick={e => {
        if (preventOnClickPropagation) {
          e.stopPropagation();
        }

        if (typeof onClick !== 'undefined') {
          onClick();
        }
      }}
    />
  );
};

export default Icon;
