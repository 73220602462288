import { colorToCode } from '~/neo-ui/packages/color/Color.gen';

/**
 * Some common drop shadow values for styling components
 *
 * The larger the number, the higher the component height casting the drop shadow
 */
type BoxShadow = 'shadow0' | 'shadow100' | 'shadow200' | 'shadow300' | 'shadow400';
export const boxShadowMap: { [index: string]: string } = {
  shadow0: `0rem`,
  shadow100: `0 0.063rem 0.25rem 0 ${colorToCode('dark-900-24')}`,
  shadow200: `0 0.188rem 0.5rem 0 ${colorToCode('dark-900-24')}`,
  shadow300: `0 0.375rem 1rem 0 ${colorToCode('dark-900-24')}`,
  shadow400: `0 0.75rem 1.5rem 0 ${colorToCode('dark-900-24')}`,
};

/**
 * Converts the given padding value to its css value in rem
 */
export const boxShadowToCode = (boxShadow?: BoxShadow) => {
  if (boxShadow === undefined) {
    return boxShadowMap.shadow0;
  }
  return boxShadowMap[boxShadow];
};

export default BoxShadow;
